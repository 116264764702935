/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ILoginResponse } from '../models/ILoginResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LoginService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns any
     * @throws ApiError
     */
    public postLoginLogin({
        requestBody,
    }: {
        requestBody: {
            email: string;
            redirectUrl: string;
            intendedUrl?: string;
        },
    }): CancelablePromise<{
        next: 'username' | 'password' | 'oauth';
        authorizeUrl?: string;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/login',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ILoginResponse
     * @throws ApiError
     */
    public postLoginPasswordLogin({
        requestBody,
    }: {
        requestBody: {
            email: string;
            password: string;
        },
    }): CancelablePromise<ILoginResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/login/password',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ILoginResponse
     * @throws ApiError
     */
    public postLoginSsoCallback({
        requestBody,
    }: {
        requestBody: {
            redirectUrl: string;
            code?: string;
            error?: string;
            state: string;
            preferredTenantId?: string;
        },
    }): CancelablePromise<ILoginResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/login/sso',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public postLoginRenew(): CancelablePromise<{
        jwt: string;
        expiresAt: number;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/login/renew',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public postLoginSwitchTenant({
        requestBody,
    }: {
        requestBody: {
            tenantId: string;
        },
    }): CancelablePromise<{
        jwt: string;
        expiresAt: number;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/login/switch-tenant',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
